.custom-description-text {
    font-size: 14pt;
    font-weight: 500 !important;
    color: white;
}

.custom-description-text:hover {
    color: #f34960;
    border-color: #f34960;
    font-weight: 300 !important;
}

.text-description {
    font-size: 2.2rem;
    font-weight: 900 !important;
}

.custom-button {
    background-color: rgba(239, 8, 8, 0.1);
}

.get-your-genius {
    text-align: center;
    font-size: 1.6rem;
}

.custom-desc-text {
    font-weight: 500 !important;
    color: rgb(201, 201, 201);
}
