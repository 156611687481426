body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* For Webkit browsers like Chrome, Safari */
::-webkit-scrollbar {
    width: 12px;
}

::-webkit-scrollbar-track {
    background-color: #000000;
    /* Dark Slate Gray for Scrollbar Track */
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background-color: #f34960;
    /* Warm Gold for Scrollbar Thumb */
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #ff8c00;
    /* Warm Dark Orange for Hovered Scrollbar Thumb */
}

/* For Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: #f34960 #000000;
    /* thumb and track */
}
